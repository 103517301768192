import { NextSeo } from "next-seo";
import Image from "next/image";
import { Disclosure, Popover, Transition } from "@headlessui/react";
import { Fragment, ReactNode, useState } from "react";
import { cn, useLoaded } from "utils";
import Link from "next/link";
import {
  ArrowDownIcon,
  Button,
  Divider,
  FacebookIcon,
  HamburgerIcon,
  InstagramIcon,
  LinkedInIcon,
  SupportIcon,
  XIcon,
  ChangelogIcon,
  RoadmapIcon,
  BlogIcon,
  OfficeHoursIcon,
  Collapsible,
  CollapsibleTrigger,
  CollapsibleContent,
} from "legacy-ui";
import { Toaster } from "react-hot-toast";
import { useTheme } from "next-themes";
import LogoMark from "../icons/logomark";
import auraListingsLight from "public/images/aura-listings-light.jpg";
import { useAuth } from "@/lib/hooks/auth";

const socials = [
  {
    name: "Facebook",
    href: "https://www.facebook.com/useaura",
    icon: <FacebookIcon className="size-4 flex-none" />,
  },
  {
    name: "Instagram",
    href: "https://www.instagram.com/goaura/",
    icon: <InstagramIcon className="size-4 flex-none" />,
  },
  {
    name: "X",
    href: "https://x.com/useaura",
    icon: <XIcon className="size-4 flex-none" />,
  },
  {
    name: "LinkedIn",
    href: "https://www.linkedin.com/company/useaura/",
    icon: <LinkedInIcon className="size-4 flex-none" />,
  },
];

const resources = [
  {
    name: "Blog",
    description: "Industry insights",
    href: "/blog",
    icon: BlogIcon,
  },
  {
    name: "Changelog",
    description: "Aura updates",
    href: "https://feedback.goaura.com/changelog",
    icon: ChangelogIcon,
  },
  {
    name: "Roadmap",
    description: "Upcoming features",
    href: "https://feedback.goaura.com",
    icon: RoadmapIcon,
  },
  {
    name: "Office Hours",
    description: "Learn, share, and grow",
    href: "/officehours",
    icon: OfficeHoursIcon,
  },
];

export default function MarketingLayout({
  children,
  title,
  titlePrefixSuffix = "Aura",
  footerVariant = "default",
  seo = true,
}: {
  children: ReactNode;
  title?: string;
  titlePrefixSuffix?: string;
  footerVariant?: "default" | "preview" | "early_stage";
  seo?: boolean;
}) {
  const loaded = useLoaded();
  // This also ensures we make the page() call on marketing pages
  const { authUser, track } = useAuth();
  const [resourcesShowing, setResourcesShowing] = useState(false);
  const { resolvedTheme, theme } = useTheme();

  return (
    <>
      {seo ? (
        <NextSeo
          title={
            title
              ? `${title} | ${titlePrefixSuffix}`
              : `${titlePrefixSuffix} | The Modern Amazon Repricer`
          }
          description="Aura is the first modern Amazon repricer that prioritizes performance with amazing customer support. Having supported over 10k+ sellers, we know how to help."
          openGraph={{
            type: "website",
            url: `https://goaura.com`,
            title: "Aura | The Modern Amazon Repricer",
            images: [
              {
                url: "https://goaura.com/images/og.jpg",
                width: 1200,
                height: 630,
                alt: "Aura",
              },
            ],
          }}
        />
      ) : null}

      <style jsx global>{`
        :root,
        html,
        body {
          background-color: #ffffff !important;
          overflow-x: hidden;
        }
      `}</style>

      <Transition
        appear
        as="div"
        show={true}
        enter="transition-opacity ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="flex min-h-screen flex-col font-marketing text-legacy-neutral-900"
      >
        <Disclosure
          as="header"
          className="fixed top-0 z-[60] w-full bg-white/90 backdrop-blur-lg"
        >
          {({ open, close }) => {
            // Prevent scrolling while nav is open
            if (loaded) {
              document.body.classList.toggle("overflow-y-hidden", open);
              document.documentElement.classList.toggle(
                "overflow-y-hidden",
                open
              );
            }

            return (
              <>
                <div className="relative z-40 mx-auto flex h-16 max-w-screen-desktop items-center justify-between bg-white px-4 tablet:px-20">
                  <div className="flex justify-start tablet:grow tablet:basis-0">
                    <Link
                      href="/?r=0"
                      className="z-[60] -m-2.5 p-2.5"
                      onClick={() => track("Nav Logo Clicked")}
                    >
                      <LogoMark className="-ml-px size-6 flex-none p-px text-neutral-0" />
                    </Link>
                  </div>

                  {/* Desktop nav */}
                  <div className="hidden space-x-6 text-legacy-neutral-500 laptop:flex">
                    <Button variant="marketing_text_secondary" href="/?r=0">
                      Product
                    </Button>
                    <Button variant="marketing_text_secondary" href="/company">
                      Company
                    </Button>
                    <Button variant="marketing_text_secondary" href="/pricing">
                      Pricing
                    </Button>
                    <Popover
                      className="relative"
                      onMouseEnter={() => setResourcesShowing(true)}
                      onMouseLeave={() => setResourcesShowing(false)}
                      onFocus={() => setResourcesShowing(true)}
                      onBlur={() => setResourcesShowing(false)}
                    >
                      <Popover.Button
                        as={Button}
                        variant="marketing_text_secondary"
                        className={
                          resourcesShowing ? "text-legacy-neutral-900" : ""
                        }
                      >
                        Resources
                        <ArrowDownIcon
                          className={cn(
                            "size-4 flex-none transition duration-200",
                            resourcesShowing ? "rotate-180" : ""
                          )}
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                        show={resourcesShowing}
                      >
                        <Popover.Panel
                          static
                          className="absolute left-1/2 z-10 flex w-screen max-w-max -translate-x-1/2 px-4 pt-6"
                        >
                          <div className="w-screen max-w-lg flex-auto overflow-hidden rounded-xl bg-white text-legacy-sm leading-6 shadow-lg">
                            <div className="grid grid-cols-2 gap-3 p-3">
                              {resources.map((resource) => (
                                <Link
                                  key={resource.name}
                                  href={resource.href}
                                  className="group relative flex items-center gap-x-2.5 rounded-lg p-4 transition duration-100 hover:bg-legacy-neutral-100"
                                  onClick={() => setResourcesShowing(false)}
                                >
                                  <div className="flex size-10 flex-none items-center justify-center rounded-xl bg-legacy-neutral-200">
                                    <resource.icon
                                      className="size-4 flex-none text-legacy-neutral-900"
                                      aria-hidden="true"
                                    />
                                  </div>
                                  <div>
                                    <p className="text-legacy-sm font-bold text-legacy-neutral-900">
                                      {resource.name}
                                      <span className="absolute inset-0" />
                                    </p>
                                    <p className="text-legacy-xs text-legacy-neutral-900">
                                      {resource.description}
                                    </p>
                                  </div>
                                </Link>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  </div>

                  <div className="hidden grow basis-0 items-center justify-end space-x-6 laptop:flex">
                    <Button
                      variant="marketing_text_secondary"
                      icon={<SupportIcon className="size-4 flex-none" />}
                      onClick={() => {
                        if ((window as any).Intercom) {
                          (window as any).Intercom("showNewMessage");
                        }
                      }}
                    >
                      Chat with us
                    </Button>

                    {authUser != null ? (
                      <Link
                        className="flex items-center gap-2.5 rounded-md bg-legacy-neutral-100 px-4 pb-2.5 pt-2 transition duration-100 hover:bg-legacy-neutral-200 dark:hover:bg-legacy-neutral-800"
                        href="/launcher"
                      >
                        <div className="my-[-3px] flex size-8 items-center justify-center rounded-full bg-legacy-neutral-400 text-legacy-xs text-white">
                          {authUser?.getFirstLetter()}
                        </div>
                        <span className="">Open app</span>
                      </Link>
                    ) : (
                      <>
                        <Button
                          variant="marketing_text_secondary"
                          href="/signin"
                        >
                          Sign in
                        </Button>
                        <Button
                          variant="marketing_black"
                          href={
                            footerVariant !== "early_stage"
                              ? "/signup"
                              : undefined
                          }
                          onClick={
                            footerVariant === "early_stage"
                              ? () => {
                                  if ((window as any).Intercom) {
                                    (window as any).Intercom(
                                      "startSurvey",
                                      38278241
                                    );
                                  }
                                }
                              : undefined
                          }
                        >
                          {footerVariant === "early_stage"
                            ? "Apply"
                            : "Begin trial"}
                        </Button>
                      </>
                    )}
                  </div>

                  <div className="z-[60] flex grow basis-0 justify-end laptop:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button
                      as={Button}
                      variant="marketing_text_secondary"
                      icon={<HamburgerIcon className="size-4 flex-none" />}
                    >
                      Menu
                    </Disclosure.Button>
                  </div>
                </div>

                {/* Mobile menu */}
                <Transition.Root show={open} appear>
                  <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div
                      className="fixed inset-0 z-10 min-h-dvh bg-neutral-0 bg-opacity-[0.35]"
                      onClick={() => close()}
                    />
                  </Transition.Child>

                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-y-full"
                    enterTo="translate-y-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-y-0"
                    leaveTo="-translate-y-full"
                  >
                    <Disclosure.Panel
                      static
                      className="fixed right-0 top-0 z-30 flex max-h-dvh w-full grow flex-col overflow-auto laptop:hidden"
                    >
                      {({ close }) => (
                        <div className="bg-white">
                          <div className="px-4 pt-16 tablet:px-20">
                            <div className="grid grid-cols-1 divide-y divide-legacy-neutral-200 border-t border-t-legacy-neutral-200 text-legacy-neutral-900">
                              <Link
                                className="my-auto flex h-16 items-center"
                                href="/?r=0"
                                onClick={() => close()}
                              >
                                Product
                              </Link>
                              <Link
                                className="my-auto flex h-16 items-center"
                                href="/company"
                                onClick={() => close()}
                              >
                                Company
                              </Link>
                              <Link
                                className="my-auto flex h-16 items-center"
                                href="/pricing"
                                onClick={() => close()}
                              >
                                Pricing
                              </Link>

                              <Collapsible>
                                <CollapsibleTrigger className="group my-auto flex h-16 w-full items-center gap-2.5">
                                  Resources
                                  <ArrowDownIcon
                                    className="size-4 flex-none transition duration-200 group-data-[state='open']:rotate-180"
                                    aria-hidden="true"
                                  />
                                </CollapsibleTrigger>
                                <CollapsibleContent className="overflow-hidden data-[state='closed']:animate-collapsible-up data-[state='open']:animate-collapsible-down">
                                  {resources.map((resource) => (
                                    <Link
                                      key={resource.name}
                                      href={resource.href}
                                      className="flex h-16 items-center gap-x-2.5 text-legacy-neutral-500"
                                      onClick={() => {
                                        setResourcesShowing(false);
                                        close();
                                      }}
                                    >
                                      <resource.icon
                                        className="size-4 flex-none"
                                        aria-hidden="true"
                                      />
                                      {resource.name}
                                    </Link>
                                  ))}
                                </CollapsibleContent>
                              </Collapsible>

                              <div className="grid grid-cols-1 gap-2.5 py-2">
                                {authUser != null ? (
                                  <Button
                                    variant="marketing_black"
                                    className="w-full"
                                    href="/launcher"
                                    onClick={() => close()}
                                  >
                                    Open app
                                  </Button>
                                ) : (
                                  <>
                                    <Button
                                      variant="marketing_text_secondary"
                                      href="/signin"
                                      className="pb-2.5 pt-2"
                                    >
                                      Sign in
                                    </Button>
                                    <Button
                                      variant="marketing_black"
                                      className="w-full"
                                      href="/signup"
                                      onClick={() => close()}
                                    >
                                      Begin trial
                                    </Button>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Disclosure.Panel>
                  </Transition.Child>
                </Transition.Root>
              </>
            );
          }}
        </Disclosure>

        <main className="grow pt-16">
          {children}
          <div>
            <Toaster
              position="bottom-center"
              reverseOrder={false}
              gutter={8}
              containerClassName="mb-14"
              toastOptions={{
                className:
                  "text-legacy-sm bg-legacy-neutralold-25 dark:bg-legacy-neutral-900 text-legacy-neutral-900 dark:text-white border border-solid border-legacy-neutral-200 dark:border-legacy-neutral-700",
                duration: 3200,
              }}
            />
          </div>
        </main>

        {footerVariant === "default" ? (
          <DefaultFooter />
        ) : (
          <PreviewFooter earlyStage={footerVariant === "early_stage"} />
        )}
      </Transition>
    </>
  );
}

function PreviewFooter({ earlyStage = false }: { earlyStage?: boolean }) {
  return (
    <footer className="bg-neutral-0">
      {earlyStage ? (
        <div className="mx-auto max-w-screen-laptop px-4 py-14 tablet:px-20 tablet:py-28">
          <div className="grid grid-cols-1 gap-10 tablet:grid-cols-2">
            <div>
              <h2 className="text-legacy-3xl font-bold text-white tablet:text-legacy-5xl">
                Start growing with our Early Stage program.
              </h2>

              <p className="mt-3 text-white">$27/month with no commitment.</p>

              <Button
                variant="marketing_gray"
                href={!earlyStage ? "/signup" : undefined}
                onClick={
                  earlyStage
                    ? () => {
                        if ((window as any).Intercom) {
                          (window as any).Intercom("startSurvey", 38278241);
                        }
                      }
                    : undefined
                }
                className="mt-6"
              >
                Apply
              </Button>
            </div>
          </div>

          <Divider
            className="mt-6 px-0 tablet:mt-10 tablet:px-0"
            color="border-legacy-neutral-700"
          />

          <div className="mt-6 flex items-center gap-6 text-white tablet:mt-10">
            <p>Do you have a question?</p>
            <Button
              variant="marketing_text_secondary_dark"
              icon={<SupportIcon className="size-4 flex-none" />}
              onClick={() => {
                if ((window as any).Intercom) {
                  (window as any).Intercom("showNewMessage");
                }
              }}
            >
              Chat with us
            </Button>
          </div>
        </div>
      ) : (
        <>
          <div className="mx-auto w-full max-w-screen-laptop px-4 pb-10 pt-14 tablet:px-20 tablet:pb-16 tablet:pt-28">
            <h3 className="text-legacy-xl text-white">Try Aura 1.0</h3>
          </div>

          <div className="mx-auto max-w-screen-desktop px-4 pb-14 tablet:px-20 tablet:pb-28">
            <div className="relative translate-y-0 overflow-hidden rounded-[28px] bg-black transition-all">
              <div className="absolute inset-0 flex items-center justify-center bg-gradient-to-b from-black/0 to-black">
                <Button
                  variant="marketing_white"
                  href="/signup"
                  icon={
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 22.9999C5.92488 23 1 18.0751 1 12C1 5.92487 5.92488 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 22.9999Z"
                        stroke="#1A1A1A"
                        strokeWidth="2"
                      />
                      <path
                        d="M12 16L16 12L12 8"
                        stroke="#1A1A1A"
                        strokeWidth="2"
                      />
                      <path d="M7 12L16 12" stroke="#1A1A1A" strokeWidth="2" />
                    </svg>
                  }
                >
                  Begin trial
                </Button>
              </div>

              <Image
                alt="Aura 1.0 Listings Preview"
                src={auraListingsLight}
                className="rounded-[28px]"
              />
            </div>
          </div>
        </>
      )}

      <Divider color="border-legacy-neutral-700" />

      <div className="mx-auto w-full max-w-screen-laptop px-4 py-14 tablet:px-20 tablet:pb-24 tablet:pt-28">
        <div className="grid grid-cols-1 gap-6 tablet:grid-cols-5">
          <div className="flex flex-col text-legacy-base">
            <h4 className="leading-8 text-white">Product</h4>
            <Button
              variant="marketing_text_secondary_dark"
              className="w-fit"
              href="/pricing"
            >
              <span className="leading-8">Pricing</span>
            </Button>
            <Button
              variant="marketing_text_secondary_dark"
              className="w-fit"
              href="https://docs.goaura.com"
            >
              <span className="leading-8">API</span>
              <div className="rounded-md bg-legacy-primary-900 px-1 py-0.5 text-xs text-white">
                New
              </div>
            </Button>
          </div>

          <div className="flex flex-col text-legacy-base">
            <h4 className="leading-8 text-white">Company</h4>
            <Button
              variant="marketing_text_secondary_dark"
              className="w-fit"
              href="/company"
            >
              <span className="leading-8">About us</span>
            </Button>
            <Button
              variant="marketing_text_secondary_dark"
              className="w-fit"
              href="mailto:hello@goaura.com"
            >
              <span className="leading-8">Contact us</span>
            </Button>
            <Button
              variant="marketing_text_secondary_dark"
              className="w-fit"
              href="/careers"
            >
              <span className="leading-8">Careers</span>
            </Button>
            <Button
              variant="marketing_text_secondary_dark"
              className="w-fit"
              href="/press-kit.zip"
            >
              <span className="leading-8">Press</span>
            </Button>
            <Button
              variant="marketing_text_secondary_dark"
              className="w-fit"
              href="https://aura.partnerstack.com"
            >
              <span className="leading-8">Affiliates</span>
            </Button>
          </div>

          <div className="flex flex-col text-legacy-base">
            <h4 className="leading-8 text-white">Resources</h4>
            <Button
              variant="marketing_text_secondary_dark"
              className="w-fit"
              href="https://help.goaura.com/en/collections/1961120-faq"
            >
              <span className="leading-8">FAQ</span>
            </Button>
            <Button
              variant="marketing_text_secondary_dark"
              className="w-fit"
              href="https://help.goaura.com/"
            >
              <span className="leading-8">Help Center</span>
            </Button>
            <Button
              variant="marketing_text_secondary_dark"
              className="w-fit"
              href="https://goaura.com/blog"
            >
              <span className="leading-8">Blog</span>
            </Button>
            <Button
              variant="marketing_text_secondary_dark"
              className="w-fit"
              href="https://goaura.com/officehours"
            >
              <span className="leading-8">Office Hours</span>
            </Button>
            <Button
              variant="marketing_text_secondary_dark"
              className="w-fit"
              href="https://prepcenter.com"
            >
              <span className="leading-8">Prep Centers</span>
            </Button>
            <Button
              variant="marketing_text_secondary_dark"
              className="w-fit"
              href="https://feedback.goaura.com/changelog"
            >
              <span className="leading-8">Changelog</span>
            </Button>
          </div>

          <div className="flex flex-col text-legacy-base">
            <h4 className="leading-8 text-white">Communities</h4>
            <Button
              variant="marketing_text_secondary_dark"
              className="w-fit"
              href="https://discord.gg/amazonfba"
              newTab
            >
              <span className="leading-8">Discord</span>
            </Button>
            <Button
              variant="marketing_text_secondary_dark"
              className="w-fit"
              href="https://www.facebook.com/groups/amazonwholesale/"
              newTab
            >
              <span className="leading-8">Facebook</span>
            </Button>
          </div>

          <div>
            <Button
              variant="marketing_gray"
              className="w-full"
              href={!earlyStage ? "/signup" : undefined}
              onClick={
                earlyStage
                  ? () => {
                      if ((window as any).Intercom) {
                        (window as any).Intercom("startSurvey", 38278241);
                      }
                    }
                  : undefined
              }
            >
              {earlyStage ? "Apply" : "Begin trial"}
            </Button>
          </div>
        </div>

        <Divider
          className="mt-6 px-0 tablet:mt-10 tablet:px-0"
          color="border-legacy-neutral-700"
        />

        <div className="mt-6 flex flex-col items-start gap-6 text-legacy-neutral-500 tablet:mt-10 tablet:flex-row tablet:items-center">
          <div className="inline-flex flex-wrap gap-4 text-legacy-base tablet:gap-6">
            <p>&copy; {new Date().getFullYear()} Vendrive, Inc.</p>
            <Button variant="marketing_text_secondary_dark" href="/terms">
              Terms of Service
            </Button>
            <Button variant="marketing_text_secondary_dark" href="/privacy">
              Privacy Policy
            </Button>
          </div>

          <div className="flex gap-7">
            {socials.map((item) => (
              <Button
                variant="marketing_text_secondary_dark"
                newTab
                key={item.name}
                href={item.href}
              >
                <span className="sr-only">{item.name}</span>
                {item.icon}
              </Button>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
}

function DefaultFooter() {
  return (
    <footer className="mx-auto w-full max-w-screen-laptop bg-white px-4 py-14 tablet:px-20 tablet:pb-24 tablet:pt-28">
      <div className="grid grid-cols-1 gap-6 tablet:grid-cols-5">
        <div className="flex flex-col text-legacy-base">
          <h4 className="leading-8 text-legacy-neutral-900">Product</h4>
          <Button
            variant="marketing_text_secondary"
            className="w-fit"
            href="/pricing"
          >
            <span className="leading-8">Pricing</span>
          </Button>
          <Button
            variant="marketing_text_secondary"
            className="w-fit"
            href="https://docs.goaura.com"
          >
            <span className="leading-8">API</span>
            <div className="rounded-md bg-legacy-primary-900 px-1 py-0.5 text-xs text-white">
              New
            </div>
          </Button>
        </div>

        <div className="flex flex-col text-legacy-base">
          <h4 className="leading-8 text-legacy-neutral-900">Company</h4>
          <Button
            variant="marketing_text_secondary"
            className="w-fit"
            href="/company"
          >
            <span className="leading-8">About us</span>
          </Button>
          <Button
            variant="marketing_text_secondary"
            className="w-fit"
            href="mailto:hello@goaura.com"
          >
            <span className="leading-8">Contact us</span>
          </Button>
          <Button
            variant="marketing_text_secondary"
            className="w-fit"
            href="/careers"
          >
            <span className="leading-8">Careers</span>
          </Button>
          <Button
            variant="marketing_text_secondary"
            className="w-fit"
            href="/press-kit.zip"
          >
            <span className="leading-8">Press</span>
          </Button>
          <Button
            variant="marketing_text_secondary"
            className="w-fit"
            href="https://aura.partnerstack.com"
          >
            <span className="leading-8">Affiliates</span>
          </Button>
        </div>

        <div className="flex flex-col text-legacy-base">
          <h4 className="leading-8 text-legacy-neutral-900">Resources</h4>
          <Button
            variant="marketing_text_secondary"
            className="w-fit"
            href="https://help.goaura.com/en/collections/1961120-faq"
          >
            <span className="leading-8">FAQ</span>
          </Button>
          <Button
            variant="marketing_text_secondary"
            className="w-fit"
            href="https://help.goaura.com/"
          >
            <span className="leading-8">Help Center</span>
          </Button>
          <Button
            variant="marketing_text_secondary"
            className="w-fit"
            href="https://goaura.com/blog"
          >
            <span className="leading-8">Blog</span>
          </Button>
          <Button
            variant="marketing_text_secondary"
            className="w-fit"
            href="https://goaura.com/officehours"
          >
            <span className="leading-8">Office Hours</span>
          </Button>
          <Button
            variant="marketing_text_secondary"
            className="w-fit"
            href="https://prepcenter.com"
          >
            <span className="leading-8">Prep Centers</span>
          </Button>
        </div>

        <div className="flex flex-col text-legacy-base">
          <h4 className="leading-8 text-legacy-neutral-900">Communities</h4>
          <Button
            variant="marketing_text_secondary"
            className="w-fit"
            href="https://discord.gg/amazonfba"
            newTab
          >
            <span className="leading-8">Discord</span>
          </Button>
          <Button
            variant="marketing_text_secondary"
            className="w-fit"
            href="https://www.facebook.com/groups/amazonwholesale/"
            newTab
          >
            <span className="leading-8">Facebook</span>
          </Button>
        </div>

        <div>
          <Button variant="marketing_black" className="w-full" href="/signup">
            Begin trial
          </Button>
        </div>
      </div>

      <Divider
        className="mt-6 px-0 tablet:mt-10 tablet:px-0"
        color="border-legacy-neutral-200"
      />

      <div className="mt-6 flex flex-col items-start gap-6 text-legacy-neutral-500 tablet:mt-10 tablet:flex-row tablet:items-center">
        <div className="inline-flex flex-wrap gap-4 text-legacy-base tablet:gap-6">
          <p>&copy; {new Date().getFullYear()} Vendrive, Inc.</p>
          <Button variant="marketing_text_secondary" href="/terms">
            Terms of Service
          </Button>
          <Button variant="marketing_text_secondary" href="/privacy">
            Privacy Policy
          </Button>
        </div>

        <div className="flex gap-7">
          {socials.map((item) => (
            <Button
              variant="marketing_text_secondary"
              newTab
              key={item.name}
              href={item.href}
            >
              <span className="sr-only">{item.name}</span>
              {item.icon}
            </Button>
          ))}
        </div>
      </div>
    </footer>
  );
}
